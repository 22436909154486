
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsfb4147e2a050bd0ffae4b23a27603406en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'fb4147e2a050bd0ffae4b23a27603406', flattenObject(nsfb4147e2a050bd0ffae4b23a27603406en_US), true, true);
        
            import nsfb4147e2a050bd0ffae4b23a27603406en from './en.yaml'
            i18n.addResourceBundle('en', 'fb4147e2a050bd0ffae4b23a27603406', flattenObject(nsfb4147e2a050bd0ffae4b23a27603406en), true, true);
        
        export default 'fb4147e2a050bd0ffae4b23a27603406';
    