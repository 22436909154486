// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import Voucher from 'components/Voucher'
import MainLogo from '../MainLogo'
import { Store } from 'Store'
import {
    svgToImage,
    // downloadBlob
} from '@shift-marketing/event-websites-helper'
import { sendAutoresponder, sendAppointmentDate, sendClientMessage } from 'Actions'
import { AppointmentWidgetWrapper } from '@shift-marketing/event-websites-chatbot'
import '@shift-marketing/event-websites-chatbot/dist/main.css'
import useTimeout from 'hook/useTimeout'
import giftOverlay from '../Hero/images/gifts_overlay.png'
import { Currency } from 'i18n/NumberFormatter'

function HeaderWithVoucher (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)
    const [voucherMounted, setVoucherMounted] = React.useState(null)
    const [innerVoucherMounted, setInnerVoucherMounted] = React.useState(null)
    const [appointmentWidget, setAppointmentWidget] = React.useState(false)

    React.useEffect(() => {
        if (!state.autoresponderShouldBeSent) return

        if (voucherMounted && innerVoucherMounted) {
            setTimeout(() => {
                svgToImage(voucherMounted, async blob => {
                    // await downloadBlob(blob, `${state.campaign.incentives.voucher.name}.png`)
                    await sendAutoresponder(dispatch, `${state.campaign.incentives.voucher.name}.png`, blob)
                })
            }, 250) // Wait until fonts are loaded ( Workaround ).
        }
    }, [dispatch, state.campaign.incentives.voucher.name, state.autoresponderShouldBeSent, voucherMounted, innerVoucherMounted])

    useTimeout(() => {
        if (state.campaign.hasOwnProperty('incentives') && state.campaign.incentives.hasOwnProperty('onlineBooking') && !state.campaign.expired) {
            let localTimezone
            try {
                localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch {
                localTimezone = state.campaign.timezone
            }
            setAppointmentWidget(
                <AppointmentWidgetWrapper
                    onlineBooking={state.campaign.incentives.onlineBooking}
                    timezone={localTimezone}
                    lead={state.lead}
                    clientName={state.campaign.client.name}
                    expiryDate={state.campaign.expiryDate}
                    colors={theme.colors}
                    actions={{
                        sendAppointmentDate,
                        sendClientMessage
                    }}
                    currencyFormatter={Currency(state.currency, state.locale)}
                />
            )
        }
    }, 5000)

    const voucherRefCallback = React.useCallback(voucher => {
        if (voucher) {
            setVoucherMounted(voucher)
        }
    }, [])

    const innerVoucherRefCallback = React.useCallback(voucher => {
        if (voucher) {
            setInnerVoucherMounted(voucher)
        }
    }, [])

    return (
        <>
            {appointmentWidget}
            <div className={styles.bgWrapper} style={{ backgroundImage: `url(${theme.background})` }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo logoColor="#E52937" titleColor="#545D66" title={state.campaign.client.name}/>
                    </div>
                </div>

                <div className={styles.voucher}>
                    <Voucher
                        width="100%"
                        ref={voucherRefCallback}
                        innerLogoRef={innerVoucherRefCallback}
                    />
                </div>

                <div className={styles.cars}>
                    <div className={styles.carsInner}>
                        <img src={theme.cars} alt="Cars"/>
                        <img className={styles.giftOverlay} src={giftOverlay} alt=""/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(HeaderWithVoucher)
