// @flow
import * as React from 'react'

import cn from 'classnames'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'

import RegistrationForm from 'components/RegistrationForm'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import MainLogo from '../MainLogo'
import { Store } from 'Store'

import type { Theme } from './themes/__supportedThemes'

import giftOverlay from './images/gifts_overlay.png'
import giftOverlayMobile from './images/gifts_overlay_mobile.png'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme } = props
    const { state } = React.useContext(Store)

    return (
        <div className={styles.mainContainer}>
            <div className={styles.bgWrapper} style={{ backgroundImage: `url(${theme.background})` }}>

                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo logoColor="#E52937" titleColor="#545D66" title={state.campaign.client.name} />
                    </div>

                    <div className={styles.registerForm}>
                        <div className={styles.formContainer}>

                            <div className="register-text-masked-layers js-register-text-masked-layers">
                                <h3 className="register-text-layer js-masked-layer">
                                    <span>Out with the old.</span> <strong>In with the new!</strong>
                                </h3>
                            </div>

                            <RegistrationForm/>
                        </div>
                    </div>
                </div>

                <div className="register-cars-masked-layers js-register-cars-masked-layers">
                    <div className="register-cars-layer js-masked-layer">
                        <MediaQuery minWidth={768}>
                            <img className={styles.giftOverlay} src={giftOverlay} alt="" />
                            <img src={theme.carsNew} alt="" />
                        </MediaQuery>
                        <MediaQuery maxWidth={767}>
                            <img className={cn(styles.giftOverlay, styles.giftOverlayMobile)} src={giftOverlayMobile} alt="" />
                            <img src={theme.carsNewMobile} alt="" />
                        </MediaQuery>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
