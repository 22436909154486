// @flow
import * as React from 'react'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import { Store } from 'Store'

function ExtraIncentive (props) {
    const { t, theme } = props
    const { state } = React.useContext(Store)

    return (
        <div className={cn(styles.bgWrapper, props.className)}>
            <p className={styles.title}>{t('title', {
                name: state.lead.fields.firstName
            })}</p>
            <img alt={''} className={styles.car} src={theme.car} />
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(ExtraIncentive))
