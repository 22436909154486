// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import MainLogo from '../MainLogo'
import { Store } from 'Store'

import giftOverlay from './images/gifts_overlay.png'

function Header (props) {
    const { theme } = props
    const { state } = React.useContext(Store)

    return (
        <>
            <div className={styles.bgWrapper} style={{ backgroundImage: `url(${theme.background})` }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo logoColor="#E52937" titleColor="#545D66" title={state.campaign.client.name} />
                    </div>
                </div>

              <div className={styles.cars}>
                <div className={styles.carsInner}>
                  <img className={styles.giftOverlay} src={giftOverlay} alt="" />
                  <img src={theme.cars} alt="Cars" />
                </div>
              </div>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(Header)
